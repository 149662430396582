const SET_GLOBAL_MESSAGE = "SET_GLOBAL_MESSAGE"
const CLOSE_GLOBAL_MESSAGE = "CLOSE_GLOBAL_MESSAGE"

type GlobalMessageAction = "success" | "warning" | "error" | "info"

export interface GlobalMessageState {
  open: boolean
  message: string
  action: GlobalMessageAction
}

interface SetGlobalMessage {
  type: typeof SET_GLOBAL_MESSAGE
  message: string
  action: GlobalMessageAction
}

interface CloseGlobalmessage {
  type: typeof CLOSE_GLOBAL_MESSAGE
}

type GlobalMessageActionsTypes = SetGlobalMessage | CloseGlobalmessage

const initialState = {
  open: false,
  message: "",
  action: "info"
} as GlobalMessageState

export default function globalMessageReducer(
  state = initialState,
  action: GlobalMessageActionsTypes
): GlobalMessageState {
  switch (action.type) {
    case SET_GLOBAL_MESSAGE:
      return {
        ...state,
        open: true,
        message: action.message,
        action: action.action
      }
    case CLOSE_GLOBAL_MESSAGE:
      return {
        ...state,
        open: false
      }
    default:
      return state
  }
}

export const setGlobalMessageAction = (message: string, action: GlobalMessageAction): GlobalMessageActionsTypes => ({
  type: SET_GLOBAL_MESSAGE,
  message: message,
  action: action
})

export const closeGlobalMessageAction = (): GlobalMessageActionsTypes => ({
  type: CLOSE_GLOBAL_MESSAGE
})
