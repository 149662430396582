import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Message } from "@basset-la/components-commons"
import { GlobalMessageState, closeGlobalMessageAction } from "../../../reducers/globalMessage"

const GlobalMessage: React.FC = () => {
  const dispatch = useDispatch()
  const message = useSelector((state: any) => state.globalMessageReducer as GlobalMessageState)

  return (
    <Message
      open={message.open}
      message={message.message}
      action={message.action}
      variant="snackbar"
      onClose={() => dispatch(closeGlobalMessageAction())}
    />
  )
}

export default GlobalMessage
