import React from "react"
import ReactDOM from "react-dom"

import "./i18n/i18n"
import "./index.css"

import Root from "./Root"

require("dotenv").load()

// used for material-ui
// https://material-ui.com/style/typography/#migration-to-typography-v2
// @ts-ignore
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true

ReactDOM.render(<Root />, document.getElementById("root"))
