import { createStore, applyMiddleware, Store, combineReducers } from "redux"
import { createLogger } from "redux-logger"
import { routerMiddleware, startListener } from "redux-first-routing"
import thunk from "redux-thunk"
import { History } from "history"

import globalMessageReducer from "./reducers/globalMessage"

export const configureStore = (history: History): Store => {
  const reducers = combineReducers({ globalMessageReducer })
  const store = createStore(reducers, applyMiddleware(thunk, createLogger(), routerMiddleware(history)))
  startListener(history, store)
  return store
}
