import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import Frame from "./components/commons/Frame/Frame"
const Login = React.lazy(() => import("./components/Login/Login"))
const AgencySelector = React.lazy(() => import("./components/AgencySelector/AgencySelector"))
const SecurityAlert = React.lazy(() => import("./components/SecurityAlert/SecurityAlert"))
const Signup = React.lazy(() => import("./components/Signup/Signup"))
const Welcome = React.lazy(() => import("./components/Welcome/Welcome"))

const Routes: React.FC = () => {
  const { logout } = useAuth0()

  return (
    <Switch>
      <Route
        exact
        path="/auth/login"
        render={() => (
          <Frame pageTitleKey={"Routes.loginTitle"}>
            <Login />
          </Frame>
        )}
      />

      <Route
        exact
        path="/auth/agency-selector"
        render={() => (
          <Frame pageTitleKey={"Routes.loginTitle"}>
            <AgencySelector />
          </Frame>
        )}
      />

      <Route
        exact
        path="/auth/security-alert"
        render={() => (
          <Frame pageTitleKey={"Routes.loginTitle"}>
            <SecurityAlert />
          </Frame>
        )}
      />

      <Route
        exact
        path="/auth/signup"
        render={() => (
          <Frame pageTitleKey={"Routes.signupPageTitle"}>
            <Signup />
          </Frame>
        )}
      />

      <Route
        exact
        path="/auth/welcome"
        render={() => (
          <Frame pageTitleKey={"Routes.welcomePageTitle"}>
            <Welcome />
          </Frame>
        )}
      />

      <Route
        exact
        path="/auth/logout"
        render={() => {
          logout({
            returnTo: process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_TO,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID
          })
          return (
            <Frame pageTitleKey={"Routes.loginTitle"}>
              <span />
            </Frame>
          )
        }}
      />

      <Redirect exact from="/auth" to="/auth/login" />
    </Switch>
  )
}

export default Routes
