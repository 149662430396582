import React from "react"
import styles from "./Loading.styles"

import { img } from "../../../utils/common"

interface Props {
  fullScreen?: boolean
}

const Loading: React.FC<Props> = ({ fullScreen = true }) => {
  return (
    <div className={`${fullScreen ? styles.fullWidthContainer : styles.container}`}>
      <div className={`${fullScreen && styles.fullWidthLoading}`} />
      <img alt={""} className={`${styles.bone}`} src={img("/bone.gif")} />
    </div>
  )
}

export default Loading
