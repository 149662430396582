import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../../utils/constants"
import GlobalMessage from "../GlobalMessage/GlobalMessage"
import { css } from "glamor"
import BassetProvider from "@basset-la/themed-components/dist/components/BassetProvider/BassetProvider"
import theme from "./bassetTheme.json"

interface Props {
  children: React.ReactNode
  pageTitleKey: string
}

const Frame: React.FC<Props> = ({ children, pageTitleKey }) => {
  const { t } = useTranslation(I18N_NS)

  useEffect(() => {
    document.title = t(pageTitleKey)
  }, [pageTitleKey, t])

  return (
    <BassetProvider theme={theme}>
      <main className={`${mainCtn}`}>
        {children}
        <GlobalMessage />
      </main>
    </BassetProvider>
  )
}

const mainCtn = css({
  minHeight: `100vh`
})

export default Frame
