import { CountryDTO } from "../api/geo"

export const AVAILABLE_LANGUAGES = ["es", "en"]

export const img = path => {
  return process.env.PUBLIC_URL + "/img" + path
}

export const statics = path => {
  return process.env.REACT_APP_BASSET_STATIC_URL + path
}

export const redirectToExternal = (path: string | null) => {
  let location = path
  if (!path) {
    location = "/"
  }
  window.location.href =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "") +
    location
}

export const isEmailValid = value => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
}

export const validPassword = value => {
  return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}/.test(value)
}

export const getLanguage = (language?: string): string => {
  const lang = language?.split("-")[0]

  if (!lang) {
    return "es"
  }

  if (lang === "dev") {
    return "es"
  }

  if (!AVAILABLE_LANGUAGES.includes(lang)) {
    return "es"
  }

  return lang
}

export const getCountryName = (name: { [key: string]: string }, language: string): string => {
  if (name[language]) {
    return name[language]
  }

  return name["es"]
}

export const sortCountriesByLanguage = (countries: CountryDTO[], language: string): CountryDTO[] => {
  return countries.sort((c1, c2) => {
    const countryName1 = getCountryName(c1.name, language)

    const countryName2 = getCountryName(c2.name, language)

    return countryName1.localeCompare(countryName2)
  })
}
