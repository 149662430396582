import { css } from "glamor"

export default {
  bone: css({
    width: "100px",
    zIndex: 3
  }),
  container: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: "368px"
  }),
  fullWidthContainer: css({
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }),
  fullWidthLoading: css({
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: "0.8",
    backgroundColor: "#FFF",
    zIndex: 1
  })
}
